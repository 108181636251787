import React from "react";
import { StyleSheet, Text, View, TextInput, Platform } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Constants
import { colors } from "../../assets/constants";

const InputBox = (props) => {
  // Prop Destructuring
  const {
    label = "",
    placeholder = "",
    value = "",
    setValue = () => {},
    onChangeText = () => {},
    keyboardType = "default",
    autoCapitalize = "none",
    autoComplete = false,
    autoCorrect = false,
    disabled = false,
  } = props;

  return (
    <View style={styles.container}>
      <Text style={{ ...styles.label, opacity: disabled ? 0.5 : 1 }}>
        {label}
      </Text>
      <TextInput
        style={{ ...styles.input, opacity: disabled ? 0.5 : 1 }}
        placeholder={placeholder}
        value={value}
        setValue={setValue}
        onChangeText={disabled ? null : onChangeText}
        keyboardType={keyboardType}
        autoCapitalize={autoCapitalize}
        autoComplete={autoComplete}
        autoCorrect={autoCorrect}
      />
    </View>
  );
};

export default InputBox;

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "orange",
    width: "100%",
  },
  label: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
  },
  input: {
    borderWidth: Platform.OS === "web" ? hp(0.3) : hp(0.2),
    borderColor: colors.inputBorder,
    borderRadius: hp(0.5),
    paddingVertical: hp(1.5),
    paddingHorizontal: hp(1.3),
  },
});
