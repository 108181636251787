import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { Feather, FontAwesome5 } from "@expo/vector-icons";
import moment from "moment";

// Context Variables
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import Header from "../../components/common/Header";
import PageTitle from "../../components/common/PageTitle";
import SizedBox from "../../components/common/SizedBox";

const ShipmentCard = (props) => {
  // Prop Destructuring
  const { weight = "", amount = "", date = "" } = props;

  return (
    <View style={styles.cardContainer}>
      <View style={styles.cardRow}>
        <View style={styles.cardRowSection}>
          <Feather
            name="calendar"
            size={Platform.OS === "web" ? hp(10) : hp(4)}
            color="black"
          />
        </View>
        <View style={{ ...styles.cardRowSection, flex: 4 }}>
          <Text style={styles.cardText}>
            Date Received: {moment(date).format("MM/DD/YYYY")}
          </Text>
        </View>
      </View>
      <SizedBox height={2} />
      <View style={styles.cardRow}>
        <View style={styles.cardRowSection}>
          <FontAwesome5
            name="weight-hanging"
            size={Platform.OS === "web" ? hp(10) : hp(4)}
            color="black"
          />
        </View>
        <View style={{ ...styles.cardRowSection, flex: 4 }}>
          <Text style={styles.cardText}>Total Weight: {weight} LBS</Text>
        </View>
      </View>
      <SizedBox height={2} />
      <View style={styles.cardRow}>
        <View style={styles.cardRowSection}>
          <FontAwesome5
            name="money-bill-alt"
            size={Platform.OS === "web" ? hp(10) : hp(4)}
            color="black"
          />
        </View>
        <View style={{ ...styles.cardRowSection, flex: 4 }}>
          <Text style={styles.cardText}>Settlement Amount: ${amount || 0}</Text>
        </View>
      </View>
    </View>
  );
};

const PreviousShipments = (props) => {
  // Prop Destructuring
  const { navigation } = props;

  // Context Variables
  const { toast, getPrevShipments } = useContext(Bcontext);

  // State Variables
  const [shipmentList, setShipmentList] = useState([]);
  const [loading, setLoading] = useState(false);

  // Mounting
  useEffect(() => {
    fetchShipments();
  }, []);

  // Shipment fetch handler
  const fetchShipments = async () => {
    setLoading(true);
    try {
      const shipmentRes = await getPrevShipments();
      //   console.log("Check teh shipments - ", shipmentRes);
      if (shipmentRes.statusCode == 200) {
        setShipmentList(shipmentRes.data);
      }
    } catch (err) {
      console.log(
        "Some issue while getting previos shipments (PreviousShipments.js) - ",
        err
      );
    }
    setLoading(false);
  };

  return (
    <View>
      <Header navigation={navigation} />
      <PageTitle navigation={navigation} text="Previous Shipments" />
      {loading ? (
        <>
          <SizedBox height={4} />
          <ActivityIndicator color={colors.primary} size="small" />
        </>
      ) : (
        <View style={{ alignItems: "center" }}>
          {shipmentList.length
            ? shipmentList.map((item) => (
                <ShipmentCard
                  key={item.Id}
                  weight={item.Total_weight_for_settlement__c}
                  amount={item.Settled_amount__c}
                  date={item.Date_received__c}
                />
              ))
            : null}
        </View>
      )}
    </View>
  );
};

export default PreviousShipments;

const styles = StyleSheet.create({
  cardContainer: {
    backgroundColor: colors.cardGrey,
    width: wp(80),
    // height: hp(10),
    paddingHorizontal: Platform.OS === "web" ? hp(6) : hp(2),
    paddingVertical: Platform.OS === "web" ? hp(4) : hp(2),
    borderRadius: hp(1),
  },
  cardRow: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  cardRowSection: {
    alignItems: "flex-start",
    flex: 1,
  },
  cardText: {
    fontSize: Platform.OS === "web" ? RFValue(10) : RFValue(14),
    fontFamily: "Poppins-Regular",
    letterSpacing: Platform.OS === "web" ? hp(0.5) : hp(0.1),
  },
});
