import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Constants
import { colors } from "../../assets/constants";

const Button = (props) => {
  // Prop Destructuring
  const {
    text = "",
    onPress = () => {},
    loading = false,
    disabled = false,
  } = props;
  return (
    <TouchableOpacity
      style={{ ...styles.button, opacity: loading || disabled ? 0.5 : 1 }}
      activeOpacity={0.7}
      onPress={onPress}
      disabled={loading || disabled}
    >
      {loading ? (
        <ActivityIndicator size="small" color={colors.white} />
      ) : (
        <Text style={styles.buttonText}>{text}</Text>
      )}
    </TouchableOpacity>
  );
};

export default Button;

const styles = StyleSheet.create({
  button: {
    backgroundColor: colors.primary,
    width: "100%",
    paddingVertical: hp(1.5),
    borderRadius: hp(1),
  },
  buttonText: {
    fontFamily: "Poppins-Bold",
    fontSize: Platform.OS === "web" ? RFValue(9) : RFValue(18),
    textAlign: "center",
    color: colors.white,
  },
});
