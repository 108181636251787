import React from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  Platform,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Constants
import { colors } from "../../assets/constants";

// Components
import SizedBox from "../common/SizedBox";

const ShipmentButton = (props) => {
  // Prop Destructuring
  const { onPress = () => {}, loading = false, disabled = false } = props;
  return (
    <TouchableOpacity
      style={{ ...styles.shipmentButton, opacity: disabled ? 0.5 : 1 }}
      activeOpacity={0.7}
      onPress={onPress}
      disabled={loading || disabled}
    >
      {loading ? (
        <ActivityIndicator size="small" color={colors.white} />
      ) : (
        <>
          <Image
            source={require("../../assets/images/truck-icon.png")}
            style={styles.truckIcon}
            resizeMode="contain"
          />
          <SizedBox height={0.5} />
          <Text style={styles.shipmentButtonText}>Add to Shipment</Text>
        </>
      )}
    </TouchableOpacity>
  );
};

export default ShipmentButton;

const styles = StyleSheet.create({
  shipmentButton: {
    backgroundColor: colors.primary,
    alignItems: "center",
    borderRadius: hp(10),
    paddingVertical: hp(1),
    paddingHorizontal: wp(2),
  },
  truckIcon: {
    // backgroundColor: "red",
    height: Platform.OS === "web" ? hp(3) : hp(2),
    width: Platform.OS === "web" ? wp(3) : wp(10),
  },
  shipmentButtonText: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(6) : RFValue(10),
    color: colors.white,
    textTransform: "uppercase",
  },
});
