import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  FlatList,
  ScrollView,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Context Variables
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import Header from "../../components/common/Header";
import PageTitle from "../../components/common/PageTitle";
import SizedBox from "../../components/common/SizedBox";
import MenuIcon from "../../components/buttons/MenuIcon";

const menuList = [
  {
    id: 1,
    name: "ALL ITEMS",
    icon: require("../../assets/images/all-icon.png"),
    screen: "Listing",
  },
  {
    id: 2,
    name: "Mother boards",
    icon: require("../../assets/images/board-icon.png"),
    screen: "Listing",
  },
  {
    id: 3,
    name: "Cell phones",
    icon: require("../../assets/images/phone-icon.png"),
    screen: "Listing",
  },
  {
    id: 4,
    name: "CPU's",
    icon: require("../../assets/images/cpu-icon.png"),
    screen: "Listing",
  },
  {
    id: 5,
    name: "ICS's",
    icon: require("../../assets/images/ics-icon.png"),
    screen: "Listing",
  },
  {
    id: 6,
    name: "Gold plated",
    icon: require("../../assets/images/plated-icon.png"),
    screen: "Listing",
  },
  {
    id: 7,
    name: "Computer parts",
    icon: require("../../assets/images/computer-icon.png"),
    screen: "Listing",
  },
  {
    id: 8,
    name: "Automotive parts",
    icon: require("../../assets/images/parts-icon.png"),
    screen: "Listing",
  },
  {
    id: 9,
    name: "Power supply",
    icon: require("../../assets/images/supply-icon.png"),
    screen: "Listing",
  },
  {
    id: 10,
    name: "Favorite Items",
    icon: require("../../assets/images/favorite-icon.png"),
    screen: "FavoriteItems",
  },
  {
    id: 11,
    name: "Item not on price list ? Send us a photo",
    icon: require("../../assets/images/upload-icon.png"),
    screen: "UploadBulk",
  },
  {
    id: 12,
    name: "Send all price list items to my email",
    icon: require("../../assets/images/email-icon.png"),
    screen: "Email",
  },
];

const PriceList = (props) => {
  // Prop Destructuring
  const { navigation, route } = props;

  // Context Variables
  const { toast, getCategories } = useContext(Bcontext);

  // State Variables
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  // Mounting
  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    setLoading(true);
    try {
      const categoryRes = await getCategories();
      // console.log("check category res - ", categoryRes);
      if (categoryRes.statusCode == 200) {
        const filteredRes = menuList.filter((element) =>
          categoryRes.data.categories.includes(element.name)
        );
        // console.log("check filtere res - ", filteredRes);
        setCategories([
          menuList[0],
          ...filteredRes,
          menuList[9],
          menuList[10],
          menuList[11],
        ]);
      }
    } catch (err) {
      console.log("Some issue while getting categories (PriceList.js) - ", err);
    }
    setLoading(false);
  };

  return (
    <ScrollView>
      <Header navigation={navigation} />
      <PageTitle navigation={navigation} text="Price List" />

      {/* Menu Icons */}
      <View style={styles.iconsContainer}>
        {loading ? (
          <ActivityIndicator size="small" color={colors.primary} />
        ) : (
          categories.map((item) => (
            <MenuIcon key={item.id} item={item} navigation={navigation} />
          ))
        )}
      </View>

      <SizedBox height={10} />
    </ScrollView>
  );
};

export default PriceList;

const styles = StyleSheet.create({
  iconsContainer: {
    // backgroundColor: "yellow",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
});
