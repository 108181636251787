import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Constants
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import SizedBox from "../common/SizedBox";

const TotalPaymentCard = (props) => {
  // Prop Destructuring
  const { weight = 0, price = 0 } = props;

  // Context Variables
  const { toast } = useContext(Bcontext);

  // State Variables
  const [loading, setLoading] = useState(false);

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <Text style={styles.cardTitle}>Total Payment</Text>
      </View>

      <View style={{ flex: 3, flexDirection: "row" }}>
        <View style={styles.table}>
          <Text style={styles.tableText}>Total LBS</Text>
          <View style={styles.box}>
            <Text style={styles.boxText}>{weight}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <Text style={styles.tableText}>Total Price</Text>
          <View style={styles.box}>
            <Text style={styles.boxText}>${price}</Text>
          </View>
        </View>
      </View>
    </View>
  );
};

export default TotalPaymentCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: colors.primary,
    width: Platform.OS === "web" ? wp(70) : wp(90),
    alignSelf: "center",
    paddingHorizontal: Platform.OS === "web" ? wp(2) : hp(2),
    paddingVertical: Platform.OS === "web" ? wp(3) : hp(1),
    borderRadius: hp(1),
  },
  cardTitle: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(9) : RFValue(14),
    textTransform: "uppercase",
  },
  table: {
    flex: 1,
  },
  tableText: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
    textAlign: "center",
  },
  box: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "#F8F6F1",
    paddingVertical: Platform.OS === "web" ? hp(3) : hp(2),
  },
  boxText: {
    fontFamily: "Poppins-Bold",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
    textAlign: "center",
  },
  removeText: {
    fontFamily: "Poppins-SemiBold",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
    textAlign: "right",
    color: colors.primary,
    textTransform: "uppercase",
  },
});
