import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { AntDesign } from "@expo/vector-icons";

// Context
import { Bcontext } from "../../../context/Bcontext";
import { colors } from "../../../assets/constants";

// Components
import SizedBox from "../../../components/common/SizedBox";
import CheckBox from "../../../components/inputs/CheckBox";

const Pallet = (props) => {
  // Prop Destructuring
  const {} = props;

  return (
    <View style={styles.container}>
      <Text style={styles.tableMainTitle}>DIMENTIONS PER PALLET (inches)</Text>

      <SizedBox height={Platform.OS === "web" ? 2 : 1} />

      {/* Table Section */}
      <View>
        <View style={styles.tableContainer}>
          <View>
            <View style={styles.tabelCell}>
              <Text style={styles.tableCellText}>Weight Per Pallet</Text>
            </View>
            <View style={styles.tabelCellBody}>
              <Text style={styles.tableCellText}>20</Text>
            </View>
          </View>
          <View>
            <View style={styles.tabelCell}>
              <Text style={styles.tableCellText}>Length</Text>
            </View>
            <View style={styles.tabelCellBody}>
              <Text style={styles.tableCellText}>36</Text>
            </View>
          </View>
          <View>
            <View style={styles.tabelCell}>
              <Text style={styles.tableCellText}>Width</Text>
            </View>
            <View style={styles.tabelCellBody}>
              <Text style={styles.tableCellText}>72</Text>
            </View>
          </View>
          <View>
            <View style={styles.tabelCell}>
              <Text style={styles.tableCellText}>Height</Text>
            </View>
            <View style={styles.tabelCellBody}>
              <Text style={styles.tableCellText}>13</Text>
            </View>
          </View>
          <View>
            <View
              style={{
                ...styles.tabelCell,
                width: Platform.OS === "web" ? wp(18) : wp(20),
              }}
            >
              <Text style={styles.tableCellText}>
                {Platform.OS === "web" ? "Checkbox for Lift gate" : "Lift gate"}{" "}
              </Text>
            </View>
            <View
              style={{
                ...styles.tabelCellBody,
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "row",
              }}
            >
              {Platform.OS === "web" ? (
                <Text style={{ ...styles.tableCellText, opacity: 0 }}>0</Text>
              ) : null}
              <CheckBox
                checked={false}
                setChecked={() => {}}
                height={Platform.OS === "web" ? 2 : 1.8}
              />
            </View>
          </View>
        </View>

        <SizedBox height={Platform.OS === "web" ? 4 : 2} />

        <TouchableOpacity
          activeOpacity={0.7}
          style={{ ...styles.container, flexDirection: "row" }}
          onPress={() => {}}
        >
          <AntDesign
            name="plus"
            size={Platform.OS === "web" ? hp(4) : hp(2)}
            color="black"
          />
          <Text style={styles.addText}>ADD ANOTHER PALLET</Text>
        </TouchableOpacity>

        <SizedBox height={Platform.OS === "web" ? 6 : 3} />

        <View style={{ ...styles.tableContainer, alignSelf: "center" }}>
          <View>
            <View style={styles.tabelCell}>
              <Text style={styles.tableCellText}>Total Pallets</Text>
            </View>
            <View style={styles.tabelCellBody}>
              <Text style={styles.tableCellText}>44</Text>
            </View>
          </View>
          <View>
            <View style={styles.tabelCell}>
              <Text style={styles.tableCellText}>Total Weight</Text>
            </View>
            <View style={styles.tabelCellBody}>
              <Text style={styles.tableCellText}>205</Text>
            </View>
          </View>
        </View>

        <SizedBox height={Platform.OS === "web" ? 6 : 3} />

        <View
          style={{
            width: Platform.OS === "web" ? wp(50) : wp(70),
            alignSelf: "center",
          }}
        >
          <Text style={styles.noteText}>
            {`Note: Price might change if pallet information provided is not accurate such as weight / dimentions. \n The pallet must be on the ground floor and where a truck can park. \n The rate does not include a pickup fee. \n You will need to drop off the boxes at a UPS store.`}
          </Text>
          <SizedBox height={Platform.OS === "web" ? 2 : 1} />
          <TouchableOpacity activeOpacity={0.7} onPress={() => {}}>
            <Text style={{ ...styles.noteText, color: colors.primary }}>
              Click here for UPS near you.
            </Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default Pallet;

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
  },
  tableMainTitle: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(8) : RFValue(14),
  },
  tableContainer: {
    flexDirection: "row",
  },
  tabelCell: {
    backgroundColor: colors.cardGrey,
    borderWidth: 2,
    paddingHorizontal: Platform.OS === "web" ? wp(3) : wp(1),
    paddingVertical: Platform.OS === "web" ? hp(2) : hp(1),
  },
  tableCellText: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(6) : RFValue(10),
    textAlign: "center",
  },
  tabelCellBody: {
    backgroundColor: colors.white,
    borderWidth: 2,
    paddingHorizontal: Platform.OS === "web" ? wp(4) : wp(2),
    paddingVertical: Platform.OS === "web" ? hp(2) : hp(1),
  },
  addText: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(9) : RFValue(14),
    paddingLeft: Platform.OS === "web" ? wp(1) : wp(2),
  },
  noteText: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
  },
});
