import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ActivityIndicator,
  Platform,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { FontAwesome, Entypo } from "@expo/vector-icons";

// Context Variabels
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";
import ImageSliderModal from "../modals/ImageSliderModal";

// Components
import SizedBox from "../common/SizedBox";
import ShipmentModal from "../modals/ShipmentModal";
import ShipmentButton from "../buttons/ShipmentButton";

const LearnMoreButton = () => {
  return (
    <TouchableOpacity
      activeOpacity={0.7}
      style={styles.moreButton}
      onPress={() => {}}
    >
      <Text style={styles.moreText}>Learn More</Text>
      <Entypo name="chevron-with-circle-right" size={hp(2)} color="#fff" />
    </TouchableOpacity>
  );
};

const ProductCard = (props) => {
  // Prop Destructuring
  const {
    id = "",
    name = "",
    image = "",
    category = "",
    price = "",
    favorite = false,
  } = props;

  // Context Variables
  const { addFavorite } = useContext(Bcontext);

  // State Variabels
  const [isFavorite, setIsFavorite] = useState(favorite);
  const [showImageModal, setShowImageModal] = useState(false);
  const [showShipmentModal, setShowShipmentModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const favoriteHandler = async () => {
    setLoading(true);
    try {
      const favRes = await addFavorite({ id });
      if (favRes.statusCode == 200) {
        setIsFavorite((prevState) => !prevState);
      }
    } catch (err) {
      console.log("Some issue while doing favorite (ProductCard.js) - ", err);
    }
    setLoading(false);
  };
  return (
    <View style={styles.container}>
      <ImageSliderModal
        id={id}
        visible={showImageModal}
        setVisible={setShowImageModal}
        // images={[{ img: image }]}
        images={image}
      />
      {showShipmentModal ? (
        <ShipmentModal
          visible={showShipmentModal}
          setVisible={setShowShipmentModal}
          {...props}
        />
      ) : null}
      <TouchableOpacity
        activeOpacity={0.7}
        onPress={() => setShowImageModal(true)}
      >
        <Image source={{ uri: image }} style={styles.image} />
      </TouchableOpacity>

      <SizedBox height={1} />

      {/* Title section */}
      <View style={styles.titleBox}>
        <Text style={styles.title}>{name}</Text>
      </View>

      {/* Body Section */}
      <View
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-end",
        }}
      >
        <View style={{ flex: 3 }}>
          <Text style={styles.category}>{category.replaceAll("_", " ")}</Text>
        </View>
        <View style={{ flex: 1 }}>
          <Text style={styles.favoriteText}>Add item to your favorites</Text>
        </View>
      </View>

      <SizedBox height={1} />

      {/* Footer Section */}
      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <View style={styles.priceBox}>
          <View style={styles.priceContainer}>
            <Text
              style={{
                ...styles.title,
                fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(11),
              }}
            >
              ${price} / LBS
            </Text>
          </View>
        </View>
        <View style={{ flex: 2, alignItems: "center" }}>
          {/* <LearnMoreButton /> */}
          <ShipmentButton onPress={() => setShowShipmentModal(true)} />
        </View>
        <View style={{ flex: 1, alignItems: "center" }}>
          <TouchableOpacity activeOpacity={0.7} onPress={favoriteHandler}>
            {loading ? (
              <ActivityIndicator size="small" color={colors.primary} />
            ) : (
              <FontAwesome
                name={isFavorite ? "star" : "star-o"}
                size={hp(4)}
                color="yellow"
              />
            )}
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

export default ProductCard;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.cardGrey,
    paddingHorizontal: Platform.OS === "web" ? hp(4) : hp(2),
    paddingVertical: Platform.OS === "web" ? hp(4) : hp(2),
    borderRadius: hp(1),
  },
  image: {
    backgroundColor: "white",
    resizeMode: "cover",
    height: Platform.OS === "web" ? hp(20) : hp(12),
    width: Platform.OS === "web" ? hp(20) : hp(12),
    alignSelf: "center",
    borderRadius: hp(1),
  },
  titleBox: {
    // backgroundColor: colors.cream,
  },
  title: {
    fontSize: Platform.OS === "web" ? RFValue(9) : RFValue(14),
    fontFamily: "Poppins-Medium",
    textTransform: "uppercase",
  },
  category: {
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(10),
    fontFamily: "Poppins-Regular",
    letterSpacing: 2,
  },
  favoriteText: {
    fontSize: Platform.OS === "web" ? RFValue(6) : RFValue(9),
    fontFamily: "Poppins-Regular",
    textAlign: "center",
  },
  priceBox: {
    flex: 1,
    paddingVertical: hp(1),
    paddingHorizontal: hp(1),
  },
  priceContainer: {
    backgroundColor: "#B3D3E6",
    width: Platform.OS === "web" ? wp(8) : wp(20),
    alignItems: "center",
  },
  moreButton: {
    flexDirection: "row",
    backgroundColor: "#000",
    borderRadius: hp(4),
    alignItems: "center",
    justifyContent: "space-evenly",
    paddingVertical: hp(1),
    width: Platform.OS === "web" ? wp(10) : wp(26),
  },
  moreText: {
    fontSize: Platform.OS === "web" ? RFValue(5) : RFValue(9),
    fontFamily: "Poppins-Regular",
    color: "#fff",
  },
});
