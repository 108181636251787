import React, { useState, useEffect, useContext } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Context
import { Bcontext } from "../../context/Bcontext";

// Screens
import Login from "../../screens/Auth/Login";
import OTP from "../../screens/Auth/OTP";
import Register from "../../screens/Auth/Register";

const AuthStack = createNativeStackNavigator();

const AuthStackScreen = ({ navigation, route }) => {
  //Context Variables
  const { authState } = useContext(Bcontext);
  return (
    <AuthStack.Navigator initialRouteName="Login">
      <AuthStack.Screen
        options={{ headerShown: false }}
        name="Login"
        component={Login}
      />
      <AuthStack.Screen
        options={{ headerShown: false }}
        name="OTP"
        component={OTP}
      />
      <AuthStack.Screen
        options={{ headerShown: false }}
        name="Register"
        component={Register}
      />
    </AuthStack.Navigator>
  );
};

export default AuthStackScreen;
