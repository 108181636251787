import React, { useState, useEffect, useContext } from "react";
import { StyleSheet, Text, View, Platform, TextInput } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import Modal from "react-native-modal";

// Components
import SizedBox from "../common/SizedBox";
import ShipmentButton from "../buttons/ShipmentButton";

// Context
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

const ShipmentModal = (props) => {
  // Prop Destructuring
  const {
    visible = false,
    setVisible = () => {},
    id = null,
    name = "",
    category = "",
  } = props;

  // Context Variables
  const { toast, addShipment } = useContext(Bcontext);

  // State Variables
  const [weight, setWeight] = useState("");
  const [loading, setLoading] = useState(false);

  const addToShipmentsHandler = async () => {
    setLoading(true);
    try {
      const shipRes = await addShipment({ id, weight });
      if (shipRes.statusCode == 200) {
        toast.show(shipRes.message);
        setVisible(false);
      }
    } catch (err) {
      console.log("Some issue while adding to shipments - ", err);
    }
    setLoading(false);
  };

  return (
    <Modal
      isVisible={visible}
      backdropOpacity={0.2}
      onBackButtonPress={() => setVisible(false)}
      onBackdropPress={() => setVisible(false)}
    >
      <View style={styles.container}>
        <Text style={styles.modalTitle}>{name}</Text>

        <SizedBox height={2} />

        <View style={{ width: "100%" }}>
          <Text style={styles.modalDescription}>{category}</Text>
        </View>

        <SizedBox height={1} />

        <Text style={styles.modalPrice}>$3.55 / LBS</Text>

        <SizedBox height={1} />

        <Text style={styles.modalDescription}>
          Please enter how many LBS you want to ship
        </Text>

        <SizedBox height={1} />

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <TextInput
            style={styles.weightInput}
            value={weight}
            onChangeText={(text) => setWeight(text)}
          />
          <Text style={styles.modalPrice}>LBS</Text>
        </View>

        <SizedBox height={Platform.OS === "web" ? 3 : 1} />

        <ShipmentButton
          disabled={weight ? false : true}
          onPress={addToShipmentsHandler}
          loading={loading}
        />
      </View>
    </Modal>
  );
};

export default ShipmentModal;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.white,
    alignSelf: "center",
    width: Platform.OS === "web" ? wp(50) : wp(70),
    alignItems: "center",
    paddingVertical: hp(2),
    paddingHorizontal: Platform.OS === "web" ? wp(2) : hp(2),
    borderWidth: 4,
    borderColor: colors.black,
  },
  modalTitle: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(8) : RFValue(14),
    textTransform: "uppercase",
  },
  modalDescription: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(5) : RFValue(10),
  },
  modalPrice: {
    fontSize: Platform.OS === "web" ? RFValue(9) : RFValue(16),
    fontFamily: "Poppins-SemiBold",
  },
  weightInput: {
    borderWidth: 1,
    borderColor: colors.cardGrey,
    width: Platform.OS === "web" ? wp(4) : wp(14),
    paddingHorizontal: hp(1.5),
    paddingVertical: hp(1),
    marginHorizontal: hp(1),
    marginVertical: hp(1),
  },
});
