import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

// Constants
import { config } from "../../config";

export const htmlPageContent = async ({ keyword }) => {
  try {
    const userData = await AsyncStorage.getItem("USER");
    const { token } = JSON.parse(userData);
    const axiosRes = await axios({
      method: "post",
      url: `${config.BASE_URL}/api/appPages`,
      headers: { Authorization: "Bearer " + token },
      data: { keyword },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while appPages load (Bcontext) - ", axiosRes);
  }
};
