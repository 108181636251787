import { useCallback } from "react";
import { Text, View, Platform } from "react-native";
import { useFonts } from "expo-font";
import { NavigationContainer } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { ToastProvider } from "react-native-toast-notifications";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Context
import { Bprovider } from "./src/context/Bcontext";
import { colors } from "./src/assets/constants";

// Screens
import Main from "./src/screens/Main";

export default function App() {
  // Fonts
  const [fontsLoaded] = useFonts({
    "Poppins-Black": require("./src/assets/fonts/Poppins-Black.ttf"),
    "Poppins-Bold": require("./src/assets/fonts/Poppins-Bold.ttf"),
    "Poppins-ExtraBold": require("./src/assets/fonts/Poppins-ExtraBold.ttf"),
    "Poppins-ExtraLight": require("./src/assets/fonts/Poppins-ExtraLight.ttf"),
    "Poppins-Light": require("./src/assets/fonts/Poppins-Light.ttf"),
    "Poppins-Medium": require("./src/assets/fonts/Poppins-Medium.ttf"),
    "Poppins-Regular": require("./src/assets/fonts/Poppins-Regular.ttf"),
    "Poppins-SemiBold": require("./src/assets/fonts/Poppins-SemiBold.ttf"),
    "Poppins-Thin": require("./src/assets/fonts/Poppins-Thin.ttf"),
  });

  // Linking
  const linking = {
    prefixes: [Linking.createURL("/"), "https://app.example.com"],
  };

  const onLayoutRootView = useCallback(async () => {
    if (fontsLoaded) {
      // await SplashScreen.hideAsync();
    }
  }, [fontsLoaded]);

  // Wait untill fonts are loaded
  if (!fontsLoaded) {
    return null;
  }

  return (
    <View style={{ flex: 1 }} onLayout={onLayoutRootView}>
      <NavigationContainer linking={linking} fallback={<Text>Loading...</Text>}>
        <ToastProvider
          placement="top" // "bottom | top"
          duration={4000}
          animationType="zoom-in" //'slide-in | zoom-in'
          // animationDuration={250}
          successColor="green"
          dangerColor="red"
          warningColor="orange"
          normalColor="gray"
          // icon={<Icon />}
          // successIcon={<SuccessIcon />}
          // dangerIcon={<DangerIcon />}
          // warningIcon={<WarningIcon />}
          textStyle={{
            fontFamily: "Poppins-Medium",
            fontSize: Platform.OS === "web" ? RFValue(8) : RFValue(14),
            color: colors.white,
          }}
          // offset={50} // offset for both top and bottom toasts
          offsetTop={Platform.OS === "web" ? hp(1) : hp(4)}
          // offsetBottom={40}
          swipeEnabled={true}
          // renderToast={(toastOptions) => JSX.Element} // implement custom toast component.
        >
          <Bprovider>
            <Main />
          </Bprovider>
        </ToastProvider>
      </NavigationContainer>
    </View>
  );
}
