import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  TouchableOpacity,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { AntDesign } from "@expo/vector-icons";

// Context Variables
import { Bcontext } from "../../context/Bcontext";
import SizedBox from "./SizedBox";

const PageTitle = (props) => {
  // Prop Destructuring
  const { navigation = null, text = "" } = props;

  // Context Variables
  const { toast } = useContext(Bcontext);

  return (
    <View style={styles.container}>
      <TouchableOpacity
        style={{ padding: hp(2) }}
        activeOpacity={0.7}
        // onPress={() => navigation && navigation.navigate("Home")}
        onPress={() => navigation && navigation.goBack()}
      >
        <AntDesign name="arrowleft" size={20} color="black" />
      </TouchableOpacity>
      <SizedBox width={16} />
      <Text style={styles.title}>{text}</Text>
    </View>
  );
};

export default PageTitle;

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "red",
    // height: hp(10),
    paddingVertical: hp(3),
    width: wp(80),
    alignItems: "center",
    justifyContent: "center",
    // alignSelf: "center",
    flexDirection: "row",
  },
  title: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(10) : RFValue(14),
  },
});
