import React from "react";
import { StyleSheet, Text, View } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";

const SizedBox = (props) => {
  // Prop Destructuring
  const { height = 0, width = 0 } = props;
  return <View style={{ height: hp(height), width: wp(width) }} />;
};

export default SizedBox;

const styles = StyleSheet.create({});
