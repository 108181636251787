import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Image,
  Platform,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Context
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants/index";

// Components
import SizedBox from "../../components/common/SizedBox";
import InputBox from "../../components/inputs/InputBox";
import Button from "../../components/buttons/Button";

const Login = (props) => {
  // Prop Destructuring
  const { navigation } = props;

  // Context Variables
  const { authState, authDispatch, userLogin, toast } = useContext(Bcontext);

  // State Variables
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  // User Login Handler
  const loginHandler = async () => {
    setLoading(true);
    try {
      if (!email) {
        toast.show("Please enter an email !");
        return setLoading(false);
      }
      const loginRes = await userLogin({ email });

      if (loginRes.statusCode == 200) {
        authDispatch({ type: "LOGIN", payload: loginRes.data });
        await AsyncStorage.setItem("USER", JSON.stringify(loginRes.data));
        navigation.push("OTP");
      }
    } catch (err) {
      console.log("Some issue while login (Login.js) - ", err);
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      {/* Top Section */}
      <View style={styles.topSection}>
        {Platform.OS === "web" ? null : <SizedBox height={10} />}
        <Image
          source={require("../../assets/images/logo.png")}
          style={styles.logoImage}
        />
        <Text style={styles.mainText}>Welcome to B.W Recycling</Text>
        <Text style={styles.subText}>Please enter your email to continue</Text>
      </View>

      <SizedBox height={4} />

      {/* Form Section */}
      <View style={styles.formSection}>
        <View style={{ width: Platform.OS === "web" ? wp(40) : wp(80) }}>
          <InputBox
            label="Email Address"
            keyboardType="email-address"
            value={email}
            onChangeText={(text) => setEmail(text)}
          />

          <SizedBox height={2} />

          <Button text="CONTINUE" onPress={loginHandler} loading={loading} />
        </View>
      </View>
    </View>
  );
};

export default Login;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.primary,
  },
  topSection: {
    // backgroundColor: "yellow",
    // height: hp(40),
    width: wp(100),
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: hp(2),
  },
  logoImage: {
    // backgroundColor: "red",
    width: hp(20),
    height: hp(20),
  },
  mainText: {
    // backgroundColor: "coral",
    fontFamily: "Poppins-Bold",
    fontSize: RFValue(20),
    color: colors.white,
    // width: wp(40),
    textAlign: "center",
  },
  subText: {
    // backgroundColor: "gold",
    fontFamily: "Poppins-Regular",
    fontSize: RFValue(12),
    color: colors.white,
    // width: wp(30),
    textAlign: "center",
  },
  formSection: {
    backgroundColor: colors.white,
    width: wp(100),
    height: hp(40),
    borderRadius: hp(4),
    alignItems: "center",
    justifyContent: "center",
  },
});
