import React, { useState, useEffect, useContext, useCallback } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  TouchableOpacity,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { useFocusEffect } from "@react-navigation/native";

// Context Varibles
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import SizedBox from "./SizedBox";

const Header = (props) => {
  // Prop Destructuring
  const { navigation = null } = props;

  // Context Variables
  const { authDispatch, userLogout, totalEarned } = useContext(Bcontext);

  // State Variables
  const [totalPrice, setTotalPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  //   // Hits when focus hits
  useFocusEffect(
    useCallback(() => {
      fetchTotalEarnings();
      return () => {};
    }, [])
  );

  const fetchTotalEarnings = async () => {
    try {
      const earningRes = await totalEarned();
      // console.log("cehck total earned - ", earningRes);
      if (earningRes?.statusCode == 200) {
        setTotalPrice(earningRes?.data[0]?.Total_paid_to_customer__c);
      }
    } catch (err) {
      console.log(
        "Some issue while fetching total earnings (Header.js) - ",
        err
      );
    }
  };

  const logoutHandler = async () => {
    setLoading(true);
    try {
      const logoutRes = await userLogout();
      console.log("chek logout res - ", logoutRes);
      if (logoutRes.statusCode == 200) {
        authDispatch({ type: "LOGOUT" });
        await AsyncStorage.clear();
      }
    } catch (err) {
      console.log("Some issue while logging out (Header.js) - ", err);
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      {/* Nav Icon Container */}
      <View style={styles.section}>
        <Image
          source={require("../../assets/images/nav-icon.png")}
          style={styles.navIcon}
        />
      </View>

      {/* Logo Container */}
      <View style={{ ...styles.section, alignItems: "center" }}>
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => navigation.navigate("Home")}
        >
          <Image
            source={require("../../assets/images/logo.png")}
            style={styles.logo}
          />
        </TouchableOpacity>
      </View>

      {/* Profile Section */}
      <TouchableOpacity
        activeOpacity={0.7}
        style={styles.profileSection}
        onPress={logoutHandler}
        disabled={loading}
      >
        {loading ? (
          <ActivityIndicator size="small" color={colors.white} />
        ) : (
          <Image
            source={require("../../assets/images/profile-image.png")}
            style={styles.profileImage}
          />
        )}
      </TouchableOpacity>

      {/* Action Cotainer */}
      <View
        style={{
          ...styles.section,
          ...styles.actionSection,
        }}
      >
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => navigation.push("NewShipment")}
          style={styles.actionBox}
        >
          <Image
            source={require("../../assets/images/cart-icon.png")}
            style={styles.actionIcon}
          />
          <Text style={styles.actionText}>My Cart</Text>
        </TouchableOpacity>
        <SizedBox height={1} />
        <TouchableOpacity
          activeOpacity={0.7}
          onPress={() => {}}
          style={styles.actionBox}
        >
          <Image
            source={require("../../assets/images/dollar-icon.png")}
            style={styles.actionIcon}
          />
          <View style={{ flexDirection: "column", alignItems: "center" }}>
            <Text style={styles.actionText}>Total Earned</Text>
            <Text style={styles.actionText}>${totalPrice}</Text>
          </View>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export default Header;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.primary,
    width: wp(100),
    // height: hp(10),
    paddingHorizontal: Platform.OS === "web" ? hp(10) : hp(2),
    paddingVertical: hp(2),
    paddingTop: Platform.OS === "web" ? hp(3) : hp(6),
    borderRadius: hp(2),
    flexDirection: "row",
    alignItems: "center",
  },
  section: {
    flex: 1,
  },
  navIcon: {
    width: hp(5),
    height: hp(5),
  },
  logo: {
    width: Platform.OS === "web" ? hp(15) : hp(12),
    height: Platform.OS === "web" ? hp(15) : hp(12),
  },
  profileSection: {
    // backgroundColor: "red",
    position: "absolute",
    right: Platform.OS === "web" ? hp(14) : hp(2),
    top: Platform.OS === "web" ? hp(2) : hp(4),
  },
  profileImage: {
    height: Platform.OS === "web" ? hp(8) : hp(6),
    width: Platform.OS === "web" ? hp(8) : hp(6),
    borderRadius: hp(100),
  },
  actionSection: {
    flex: Platform.OS === "web" ? 1 : 1.2,
    alignItems: "center",
    position: "relative",
    bottom: Platform.OS === "web" ? -hp(3.5) : -hp(2.5),
  },
  actionIcon: {
    width: hp(4),
    height: hp(4),
  },
  actionBox: {
    paddingLeft: Platform.OS === "web" ? hp(20) : hp(1),
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
  },
  actionText: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(10),
    color: colors.white,
    paddingLeft: hp(0.5),
  },
});
