import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Image,
  Platform,
  TouchableOpacity,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Context Variables
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants/index";

// Components
import SizedBox from "../../components/common/SizedBox";
import InputBox from "../../components/inputs/InputBox";
import Button from "../../components/buttons/Button";

const OTP = (props) => {
  // Prop Destructuring
  const { navigation } = props;

  // Context Varibales
  const { authState, authDispatch, checkOtp, toast } = useContext(Bcontext);

  // State Variables
  const [otp, setOtp] = useState("");
  const [loading, setLoading] = useState(false);

  // User OTP Handler which decides to login or create new-account
  const otpHandler = async () => {
    setLoading(true);
    try {
      // Form Validation
      if (!otp) {
        toast.show("Please enter OTP to continue !");
        return setLoading(false);
      }
      if (otp != authState?.user?.Onetimecode) {
        toast.show("OTP doesnot match, please check again !");
        return setLoading(false);
      }
      const otpRes = await checkOtp({ otp, email: authState?.user?.email });
      console.log("Check here the OTP response - ", otpRes);

      if (otpRes.statusCode == 200) {
        authDispatch({ type: "LOGIN", payload: otpRes.data });
        await AsyncStorage.setItem("USER", JSON.stringify(otpRes.data));
        if (otpRes?.data?.status == 0) {
          navigation.push("Register");
        } else if (otpRes?.data?.status == 1) {
          await AsyncStorage.setItem("LOGGED_IN", JSON.stringify(true));
          authDispatch({ type: "REGISTER", payload: authState?.user });
          navigation.push("Home");
        }
      }
    } catch (err) {
      console.log("Some issue while otp submission (OTP.js) - ", err);
    }
    setLoading(false);
  };

  console.log("Check here the OTP nav nav props - ", authState);

  return (
    <View style={styles.container}>
      {/* Top Section */}
      <View style={styles.topSection}>
        {Platform.OS === "web" ? null : <SizedBox height={10} />}

        <Image
          source={require("../../assets/images/logo.png")}
          style={styles.logoImage}
        />
        <Text style={styles.mainText}>Welcome to B.W Recycling</Text>
      </View>

      <SizedBox height={4} />

      {/* Form Section */}
      <View style={styles.formSection}>
        <View style={{ width: Platform.OS === "web" ? wp(40) : wp(80) }}>
          <Text style={styles.formText}>
            Please check your email for the 1-time code
          </Text>
          <InputBox
            label="OTP"
            value={otp}
            onChangeText={(text) => setOtp(text)}
          />

          <SizedBox height={2} />

          <Button text="CONTINUE" onPress={otpHandler} loading={loading} />

          <Text style={styles.resendText}>
            <TouchableOpacity activeOpacity={0.7} onPress={() => {}}>
              <Text
                style={{
                  ...styles.resendText,
                  color: colors.inputBorder,
                  top: Platform.OS === "web" ? hp(0) : hp(0.2),
                }}
              >
                click here
              </Text>
            </TouchableOpacity>{" "}
            if you did not receive an email
          </Text>
        </View>
      </View>
    </View>
  );
};

export default OTP;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.primary,
  },
  topSection: {
    // backgroundColor: "yellow",
    // height: hp(40),
    width: wp(100),
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: hp(2),
  },
  logoImage: {
    // backgroundColor: "red",
    width: hp(20),
    height: hp(20),
  },
  mainText: {
    // backgroundColor: "coral",
    fontFamily: "Poppins-Bold",
    fontSize: RFValue(20),
    color: colors.white,
    // width: wp(40),
    textAlign: "center",
  },
  subText: {
    // backgroundColor: "gold",
    fontFamily: "Poppins-Regular",
    fontSize: RFValue(12),
    color: colors.white,
    // width: wp(30),
    textAlign: "center",
  },
  formSection: {
    backgroundColor: colors.white,
    width: wp(100),
    height: hp(40),
    borderRadius: hp(4),
    alignItems: "center",
    justifyContent: "center",
  },
  formText: {
    position: "relative",
    top: -hp(6),
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(9) : RFValue(14),
  },
  resendText: {
    position: "relative",
    fontFamily: "Poppins-Medium",
    top: hp(4),
    textAlign: "center",
    fontSize: Platform.OS === "web" ? RFValue(8) : RFValue(13),
  },
});
