import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

// Constants
import { config } from "../../config";

export const getPrevShipments = async () => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/previousShipment`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log(
      "Some issue getting previous shipments (Bcontext) - ",
      axiosRes
    );
  }
};

export const addShipment = async ({ id, weight }) => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "post",
      url: `${config.BASE_URL}/api/addShipment`,
      headers: { Authorization: "Bearer " + token },
      data: { itemId: id, weight },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while adding to shipments (Bcontext) - ", axiosRes);
  }
};

export const myCart = async () => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/myCart`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while getting my cart (Bcontext) - ", axiosRes);
  }
};

export const removeCartItem = async ({ id }) => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/removeShipment/${id}`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while removing cart item (Bcontext) - ", axiosRes);
  }
};

export const totalEarned = async () => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/totalEarned`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log(
      "Some issue while getting total earned data (Bcontext) - ",
      axiosRes
    );
  }
};
