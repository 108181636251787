import React, { useState, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Platform,
  Linking,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Constants
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";
import SizedBox from "../common/SizedBox";

const MenuIcon = (props) => {
  // Prop Destructuring
  const { navigation = null, item = {} } = props;

  // Context
  const { toast, sendAsEmail, getPdf } = useContext(Bcontext);

  // State Variables
  const [loading, setLoading] = useState(false);

  const pressHandler = () => {
    if (item.screen === "Email") {
      sendEmailHandler();
    } else {
      navigation.push(item.screen === "HtmlView" ? "HtmlView" : item.screen, {
        screenName: item.name,
      });
    }
  };

  const sendEmailHandler = async () => {
    setLoading(true);
    try {
      const emailRes = await sendAsEmail();
      if (emailRes.statusCode == 200) {
        toast.show(emailRes.message);
        const pdfRes = await getPdf();
        if (pdfRes.statusCode == 200) {
          Linking.openURL(pdfRes.data);
        }
      }
    } catch (err) {
      console.log("Some issue while sending email - ", err);
    }
    setLoading(false);
  };

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      onPress={pressHandler}
      style={styles.container}
      disabled={loading}
    >
      <Image
        source={item?.icon}
        style={styles.imageIcon}
        resizeMode="contain"
      />
      <SizedBox height={1} />
      {loading ? (
        <View
          style={{
            width: Platform.OS === "web" ? wp(17) : hp(8),
          }}
        >
          <ActivityIndicator size="small" color={colors.primary} />
        </View>
      ) : (
        <Text style={styles.iconName}>{item?.name}</Text>
      )}
    </TouchableOpacity>
  );
};

export default MenuIcon;

const styles = StyleSheet.create({
  container: {
    // backgroundColor: colors.primary,
    marginHorizontal: wp(4),
    marginVertical: hp(2),
    alignItems: "center",
  },
  imageIcon: {
    width: Platform.OS === "web" ? hp(12) : hp(8),
    height: Platform.OS === "web" ? hp(12) : hp(8),
    // borderRadius: hp(100),
  },
  iconName: {
    // backgroundColor: "red",
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(8) : RFValue(10),
    width: wp(17),
    textAlign: "center",
  },
});
