import React, { useState, useContext, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Platform,
  ActivityIndicator,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Constants
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import SizedBox from "../common/SizedBox";

const ShipmentCard = (props) => {
  // Prop Destructuring
  const { item, setIsItemRemoved } = props;

  // Context Variables
  const { toast, removeCartItem } = useContext(Bcontext);

  // State Variables
  const [loading, setLoading] = useState(false);

  // Remove from shipment cart item handler
  const removeItemHandler = async (id) => {
    setLoading(true);
    try {
      const removeRes = await removeCartItem({ id });
      // console.log("cehck teh remove res- ", removeRes);
      if (removeRes?.statusCode == 200) {
        setIsItemRemoved((prevState) => prevState + 1);
      }
    } catch (err) {
      console.log("Some sisue while removing item (ShipmentCard.js) - ", err);
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <View style={{ flex: 1 }}>
        <Text style={styles.cardTitle}>{item?.Item_name__c}</Text>
      </View>

      <View style={{ flex: 3 }}>
        <View style={{ flexDirection: "row" }}>
          <View style={styles.table}>
            <Text style={styles.tableText}>Price per LB</Text>
            <View style={styles.box}>
              <Text style={styles.boxText}>${item?.Price_per_lb__c || 0}</Text>
            </View>
          </View>
          <View style={styles.table}>
            <Text style={styles.tableText}>Weight</Text>
            <View style={styles.box}>
              <Text style={styles.boxText}>{item?.Weight__c || 0}</Text>
            </View>
          </View>
          <View style={styles.table}>
            <Text style={styles.tableText}>Total Price</Text>
            <View style={styles.box}>
              <Text style={styles.boxText}>${item?.Total_price__c || 0}</Text>
            </View>
          </View>
        </View>

        <SizedBox height={1} />
        {loading ? (
          <ActivityIndicator
            style={{ alignSelf: "flex-end", paddingHorizontal: wp(4) }}
            size="small"
            color={colors.primary}
          />
        ) : (
          <TouchableOpacity
            activeOpacity={0.7}
            onPress={() => removeItemHandler(item?.Id)}
          >
            <Text style={styles.removeText}>Remove Item</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

export default ShipmentCard;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: colors.cardGrey,
    width: Platform.OS === "web" ? wp(70) : wp(90),
    alignSelf: "center",
    paddingHorizontal: Platform.OS === "web" ? wp(2) : hp(2),
    paddingVertical: Platform.OS === "web" ? wp(1) : hp(1),
    borderRadius: hp(1),
  },
  cardTitle: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(9) : RFValue(14),
    textTransform: "uppercase",
  },
  table: {
    flex: 1,
  },
  tableText: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
    textAlign: "center",
  },
  box: {
    flex: 1,
    borderWidth: 1,
    borderColor: "#000",
    backgroundColor: "#F8F6F1",
    paddingVertical: Platform.OS === "web" ? hp(3) : hp(2),
  },
  boxText: {
    fontFamily: "Poppins-Bold",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
    textAlign: "center",
  },
  removeText: {
    fontFamily: "Poppins-SemiBold",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
    textAlign: "right",
    color: colors.primary,
    textTransform: "uppercase",
  },
});
