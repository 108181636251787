import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  TouchableOpacity,
  Image,
  ScrollView,
  Platform,
  ActivityIndicator,
  FlatList,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { Ionicons } from "@expo/vector-icons";

// Context
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import SizedBox from "../../components/common/SizedBox";
import Header from "../../components/common/Header";
import PageTitle from "../../components/common/PageTitle";
import ShipmentCard from "../../components/cards/ShipmentCard";
import TotalPaymentCard from "../../components/cards/TotalPaymentCard";

const NewShipment = (props) => {
  // Prop Destructuring
  const { navigation = null } = props;

  // Context Variables
  const { toast, myCart } = useContext(Bcontext);

  // State Variables
  const [cartList, setCartList] = useState([]);
  const [totalWeight, setTotalWeight] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isItemRemoved, setIsItemRemoved] = useState(0);
  const [loading, setLoading] = useState(true);

  // Mounting
  useEffect(() => {
    fetchCartData();
  }, [isItemRemoved]);

  // Fetching the cart data handler
  const fetchCartData = async () => {
    setLoading(true);
    try {
      const cartRes = await myCart();
      // console.log("Check the cart res - ", cartRes);
      if (cartRes?.statusCode == 200) {
        setCartList(cartRes.data);
        let sumWeight = 0;
        let sumPrice = 0;
        cartRes.data.forEach((item) => {
          if (item.Weight__c) {
            sumWeight += item.Weight__c;
          }
          if (item.Total_price__c) {
            sumPrice += item.Total_price__c;
          }
        });
        setTotalWeight(sumWeight);
        setTotalPrice(sumPrice);
      }
    } catch (err) {
      console.log("Some issue while getting cart data - ", err);
    }
    setLoading(false);
  };

  return (
    <ScrollView style={{ flex: 1 }}>
      <Header navigation={navigation} />
      <PageTitle navigation={navigation} text="New Shipments" />

      <SizedBox height={1} />

      {loading ? (
        <ActivityIndicator size="small" color={colors.primary} />
      ) : (
        <>
          {cartList.length ? (
            <>
              <FlatList
                data={cartList}
                renderItem={({ item }) => (
                  <>
                    <ShipmentCard
                      item={item}
                      setIsItemRemoved={setIsItemRemoved}
                    />
                    <SizedBox height={1} />
                  </>
                )}
                keyExtractor={(item) => item.id}
              />

              <SizedBox height={4} />
              <TotalPaymentCard weight={totalWeight} price={totalPrice} />

              <SizedBox height={2} />
              <TouchableOpacity
                style={styles.shippingButton}
                activeOpacity={0.7}
                onPress={() => navigation.push("CalculateShipping")}
              >
                <View style={styles.shippingIcon}>
                  <Ionicons
                    name="calculator-outline"
                    size={24}
                    color={colors.white}
                  />
                </View>
                <Text style={styles.shippingButtonText}>
                  Calculate Shipping Cost
                </Text>
              </TouchableOpacity>
            </>
          ) : (
            <View>
              <SizedBox height={6} />
              <Text style={styles.emptyText}>Shipment cart is empty</Text>
            </View>
          )}
        </>
      )}

      <SizedBox height={6} />
    </ScrollView>
  );
};

export default NewShipment;

const styles = StyleSheet.create({
  shippingButton: {
    // backgroundColor: "red",
    width: Platform.OS === "web" ? wp(8) : wp(20),
    alignItems: "center",
    justifyContent: "center",
    alignSelf: "center",
  },
  shippingIcon: {
    backgroundColor: colors.primary,
    borderRadius: hp(100),
    width: hp(Platform.OS === "web" ? 8 : 6),
    height: hp(Platform.OS === "web" ? 8 : 6),
    alignItems: "center",
    justifyContent: "center",
  },
  shippingButtonText: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(6) : RFValue(10),
    textAlign: "center",
  },
  emptyText: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(12) : RFValue(16),
    color: colors.grey,
    textAlign: "center",
  },
});
