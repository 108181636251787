import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

// Constants
import { config } from "../../config";

export const getCategories = async () => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/getCategories`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue getting categories (Bcontext) - ", axiosRes);
  }
};

export const getProducts = async () => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/getPrice`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue getting products (Bcontext) - ", axiosRes);
  }
};

export const addFavorite = async ({ id }) => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "post",
      url: `${config.BASE_URL}/api/addToFavourites`,
      headers: { Authorization: "Bearer " + token },
      data: { item_id: id },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log(
      "Some issue adding product to favorite (Bcontext) - ",
      axiosRes
    );
  }
};

export const addNewItem = async ({ name, image }) => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  console.log("Chekc the image here - ", image);
  try {
    const axiosRes = await axios({
      method: "post",
      url: `${config.BASE_URL}/api/addNewItem`,
      headers: {
        Authorization: "Bearer " + token,
      },
      data: { Name: name, BW_Photo_url__c: image },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue adding new product (Bcontext) - ", axiosRes);
  }
};

export const getAttachments = async ({ id }) => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/attachments/${id}`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue getting attachments (Bcontext) - ", axiosRes);
  }
};

export const sendAsEmail = async () => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/sendAsEmail`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue sending as email (Bcontext) - ", axiosRes);
  }
};

export const getPdf = async () => {
  const userData = await AsyncStorage.getItem("USER");
  const { token } = JSON.parse(userData);
  try {
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/print`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while printing PDF (Bcontext) - ", axiosRes);
  }
};
