import React, { useState, useEffect, useReducer } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";
import { useToast } from "react-native-toast-notifications";

// Config
import { config } from "../config";

// Global functions
import {
  userLogin,
  checkOtp,
  userRegister,
  userLogout,
} from "./functions/authFunctions";
import {
  getCategories,
  getProducts,
  addFavorite,
  addNewItem,
  getAttachments,
  sendAsEmail,
  getPdf,
} from "./functions/productFunctions";
import {
  getPrevShipments,
  addShipment,
  myCart,
  removeCartItem,
  totalEarned,
} from "./functions/shipmentFunctions";
import { htmlPageContent } from "./functions/commonFunctions";

// Reducers
import { initialAuthState, authReducer } from "./reducers/authReducer";

export const Bcontext = React.createContext();

export const Bprovider = (props) => {
  // Reducer Variables
  const [authState, authDispatch] = useReducer(authReducer, initialAuthState);

  // Hook Variables
  const toast = useToast();

  // Mounting
  useEffect(() => {
    getLocalData();
  }, []);

  // Fetching local-stored data in memory
  const getLocalData = async () => {
    try {
      // await AsyncStorage.clear();
      const user = await AsyncStorage.getItem("USER");
      const loggedIn = await AsyncStorage.getItem("LOGGED_IN");
      if (loggedIn !== null && loggedIn)
        authDispatch({ type: "REGISTER", payload: JSON.parse(user) });
      if (user !== null)
        authDispatch({ type: "LOGIN", payload: JSON.parse(user) });
    } catch (err) {
      console.log(
        "Some issue while fetching local stored data (Bcontext) - ",
        err
      );
    }
  };

  // // Global method declaration to replace all
  // String.prototype.replaceAll = function (stringToFind, stringToReplace) {
  //   if (stringToFind === stringToReplace) return this;
  //   var temp = this;
  //   var index = temp.indexOf(stringToFind);
  //   while (index != -1) {
  //     temp = temp.replace(stringToFind, stringToReplace);
  //     index = temp.indexOf(stringToFind);
  //   }
  //   return temp;
  // };

  return (
    <Bcontext.Provider
      value={{
        // Reducer Variables
        authState,
        authDispatch,
        // Hook Variables
        toast,
        // Common Methods
        userLogin,
        checkOtp,
        userRegister,
        userLogout,
        getCategories,
        getProducts,
        addFavorite,
        addNewItem,
        getPrevShipments,
        getAttachments,
        addShipment,
        sendAsEmail,
        getPdf,
        myCart,
        removeCartItem,
        totalEarned,
        htmlPageContent,
      }}
    >
      {props.children}
    </Bcontext.Provider>
  );
};
