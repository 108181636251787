import AsyncStorage from "@react-native-async-storage/async-storage";
import axios from "axios";

// Constants
import { config } from "../../config";

export const userLogin = async ({ email }) => {
  try {
    const axiosRes = await axios({
      method: "post",
      url: `${config.BASE_URL}/api/login`,
      data: { email },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while login (Bcontext) - ", axiosRes);
  }
};

export const checkOtp = async ({ otp, email }) => {
  try {
    const axiosRes = await axios({
      method: "post",
      url: `${config.BASE_URL}/api/CheckStatus`,
      data: { Onetimecode: otp, email },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while checking otp status (Bcontext) - ", axiosRes);
  }
};

export const userRegister = async ({
  firstName,
  lastName,
  companyName,
  phoneNumber,
  isIndividual,
}) => {
  try {
    const userData = await AsyncStorage.getItem("USER");
    const { staus, email, token } = JSON.parse(userData);
    const axiosRes = await axios({
      method: "post",
      url: `${config.BASE_URL}/api/saveDetails`,
      headers: { Authorization: "Bearer " + token },
      data: {
        first_name: firstName,
        last_name: lastName,
        company: companyName,
        phone_no: phoneNumber,
        email,
        is_individual: isIndividual ? 1 : 0,
      },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while user register (Bcontext) - ", axiosRes);
  }
};

export const userLogout = async () => {
  try {
    const userData = await AsyncStorage.getItem("USER");
    const { token } = JSON.parse(userData);
    const axiosRes = await axios({
      method: "get",
      url: `${config.BASE_URL}/api/logout`,
      headers: { Authorization: "Bearer " + token },
    });

    return axiosRes.data;
  } catch (err) {
    const axiosRes = JSON.parse(JSON.stringify(err));
    console.log("Some issue while user logout (Bcontext) - ", axiosRes);
  }
};
