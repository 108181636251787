import React, { useState, useEffect, useContext } from "react";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Context
import { Bcontext } from "../../context/Bcontext";

// Screens
import Home from "../../screens/Home";
import PriceList from "../../screens/PriceList";
import Listing from "../../screens/PriceList/Listing";
import UploadBulk from "../../screens/PriceList/UploadBulk";
import PreviousShipments from "../../screens/PreviousShipments";
import NewShipment from "../../screens/NewShipment";
import FavoriteItems from "../../screens/PriceList/FavoriteItems";
import CalculateShipping from "../../screens/CalculateShipping";
import HtmlView from "../../screens/Shared/HtmlView";

const HomeStack = createNativeStackNavigator();

const HomeStackScreen = ({ navigation, route }) => {
  //Context Variables
  const { authState } = useContext(Bcontext);
  return (
    <HomeStack.Navigator initialRouteName="Login">
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="Home"
        component={Home}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="PriceList"
        component={PriceList}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="Listing"
        component={Listing}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="UploadBulk"
        component={UploadBulk}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="PreviousShipments"
        component={PreviousShipments}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="NewShipment"
        component={NewShipment}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="FavoriteItems"
        component={FavoriteItems}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="CalculateShipping"
        component={CalculateShipping}
      />
      <HomeStack.Screen
        options={{ headerShown: false }}
        name="HtmlView"
        component={HtmlView}
      />
    </HomeStack.Navigator>
  );
};

export default HomeStackScreen;
