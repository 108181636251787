import React, { useState, useEffect, useContext, useRef } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  ActivityIndicator,
} from "react-native";
import { ImageSlider } from "react-native-image-slider-banner";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { FontAwesome, Entypo } from "@expo/vector-icons";
import Modal from "react-native-modal";
import { AntDesign } from "@expo/vector-icons";

// Context Variables
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

const ImageSliderModal = (props) => {
  // Prop Destructuring
  const {
    visible = false,
    setVisible = () => {},
    images = [],
    id = "",
  } = props;

  // Context Variables
  const { getAttachments } = useContext(Bcontext);

  // State Variables
  const [attachments, setAttachments] = useState([]);
  const [loading, setLoading] = useState(false);

  // Mounting
  useEffect(() => {
    if (visible) fetchAttachments(id);
  }, [visible]);

  const fetchAttachments = async (id) => {
    setLoading(true);
    try {
      const fetchRes = await getAttachments({ id });
      console.log("fetched res is - ", fetchRes);
      if (fetchRes.statusCode == 200) {
        // Taking out all the attachments
        const attachedImages = fetchRes.data.map((item) => {
          return { img: item };
        });

        // Combining all the attachments with the cover image
        setAttachments([{ img: images }, ...attachedImages]);
      }
    } catch (err) {
      console.log("Some issue while fetching attachments - ", err);
    }
    setLoading(false);
  };

  return (
    <Modal
      isVisible={visible}
      backdropOpacity={0.2}
      onBackButtonPress={() => setVisible(false)}
      onBackdropPress={() => {
        setVisible(false);
      }}
    >
      {Platform.OS === "web" ? (
        <View style={styles.infoContainer}>
          <AntDesign name="infocirlceo" size={hp(2)} color="black" />
          <Text style={styles.infoText}>
            Please use track-pad or click on the image and use arrow keys for
            next image{" "}
          </Text>
        </View>
      ) : null}
      <View style={styles.modalContainer}>
        {loading ? (
          <View
            style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
          >
            <ActivityIndicator color={colors.primary} size="small" />
          </View>
        ) : (
          <View>
            <ImageSlider
              data={attachments}
              autoPlay={false}
              preview={false}
              showIndicator={Platform.OS === "web" ? false : true}
              onItemChanged={(item) => console.log("item", item)}
              closeIconColor="#fff"
              caroselImageStyle={{
                resizeMode: "contain",
                ...styles.sliderImage,
              }}
            />
          </View>
        )}
      </View>
    </Modal>
  );
};

export default ImageSliderModal;

const styles = StyleSheet.create({
  modalContainer: {
    // backgroundColor: "transparent",
    backgroundColor: colors.white,
    height: Platform.OS === "web" ? hp(50) : hp(30),
    width: Platform.OS === "web" ? wp(60) : wp(80),
    alignSelf: "center",
    borderRadius: hp(1),
    overflow: "hidden",
  },
  sliderImage: {
    height: Platform.OS === "web" ? hp(50) : hp(30),
    width: Platform.OS === "web" ? wp(60) : wp(80),
    borderRadius: hp(1),
  },
  infoContainer: {
    alignSelf: "center",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  infoText: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
    textAlign: "center",
    color: "#5A5A5A",
    paddingHorizontal: wp(0.5),
  },
});
