import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Platform,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import { WebView } from "react-native-webview";

// Context
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import SizedBox from "../../components/common/SizedBox";
import Header from "../../components/common/Header";
import PageTitle from "../../components/common/PageTitle";

const HtmlView = (props) => {
  // Prop Destructuring
  const { navigation, route } = props;

  // Context Variables
  const { htmlPageContent } = useContext(Bcontext);

  // State Variables
  const [content, setContent] = useState("");
  const [loading, setLoading] = useState(true);

  // Mounting
  useEffect(() => {
    fetchHtmlContent(route?.params?.screenName);
  }, []);

  const fetchHtmlContent = async (keyword) => {
    setLoading(true);
    try {
      const res = await htmlPageContent({ keyword });
      //   console.log("check teh html res - ", res);
      if (res.statusCode == 200) {
        setContent(res.data[0].HTML__c);
      }
    } catch (err) {
      console.log(
        "Some error while getting html content (HtmlView.js) - ",
        err
      );
    }
    setLoading(false);
  };

  return (
    <View style={styles.container}>
      <Header navigation={navigation} />
      <PageTitle navigation={navigation} text={route?.params?.screenName} />

      <SizedBox height={Platform.OS === "web" ? 2 : 0} />

      {loading ? (
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          <ActivityIndicator size="small" color={colors.primary} />
        </View>
      ) : (
        <View style={styles.webBox}>
          {Platform.OS === "web" ? (
            <>
              <iframe
                style={{ border: "none", borderWidth: 0 }}
                srcDoc={content}
              ></iframe>
            </>
          ) : (
            <WebView
              style={styles.webContainer}
              originWhitelist={["*"]}
              source={{ html: content }}
            />
          )}
        </View>
      )}
    </View>
  );
};

export default HtmlView;

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
  },
  webBox: {
    // backgroundColor: "pink",
    flex: 1,
    paddingHorizontal: Platform.OS === "web" ? wp(8) : wp(6),
  },
  webContainer: {
    // backgroundColor: "red",
    flex: 1,
  },
});
