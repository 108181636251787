import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Image,
  Platform,
  ScrollView,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import AsyncStorage from "@react-native-async-storage/async-storage";

// Context
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants/index";

// Components
import SizedBox from "../../components/common/SizedBox";
import InputBox from "../../components/inputs/InputBox";
import Button from "../../components/buttons/Button";
import CheckBox from "../../components/inputs/CheckBox";

const Register = (props) => {
  // Prop Destructuring
  const { navigation } = props;

  // Context Variables
  const { authState, authDispatch, userRegister, toast } = useContext(Bcontext);

  // State Variables
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [isIndividual, setIsIndividual] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);

  // Updating Phase
  useEffect(() => {
    if (isIndividual) setCompanyName("");
  }, [isIndividual]);

  // User Register Handler
  const registerHandler = async () => {
    setLoading(true);
    try {
      // Form Validations
      if (!firstName || !lastName || !phoneNumber) {
        toast.show("All the fields are required !");
        return setLoading(false);
      }
      if (!isIndividual && !companyName) {
        toast.show("All the fields are required !");
        return setLoading(false);
      }

      // API to register
      let registerRes;
      registerRes = await userRegister({
        firstName,
        lastName,
        companyName,
        phoneNumber,
        isIndividual,
      });
      registerRes = await userRegister({
        firstName,
        lastName,
        companyName,
        phoneNumber,
        isIndividual,
      });

      console.log("check teh register res - ", registerRes);
      if (registerRes.statusCode == 200) {
        await AsyncStorage.setItem("LOGGED_IN", JSON.stringify(true));
        authDispatch({ type: "REGISTER", payload: registerRes.data });
        navigation.navigate("Home");
      }
    } catch (err) {
      console.log("Some issue while login (Login.js) - ", err);
      toast.show("Please try again !");
    }
    setLoading(false);
  };

  return (
    <ScrollView style={styles.container}>
      {/* Top Section */}
      <View style={styles.topSection}>
        {Platform.OS === "web" ? null : <SizedBox height={10} />}
        <Image
          source={require("../../assets/images/logo.png")}
          style={styles.logoImage}
        />
        <Text style={styles.mainText}>Please Register</Text>
      </View>

      <SizedBox height={4} />

      {/* Form Section */}
      <View style={styles.formSection}>
        <View style={{ width: Platform.OS === "web" ? wp(40) : wp(80) }}>
          <InputBox
            label="First Name"
            value={firstName}
            onChangeText={(text) => setFirstName(text)}
          />

          <SizedBox height={2} />

          <InputBox
            label="Last Name"
            value={lastName}
            onChangeText={(text) => setLastName(text)}
          />

          <SizedBox height={2} />

          <InputBox
            label="Company Name"
            value={companyName}
            onChangeText={(text) => setCompanyName(text)}
            disabled={isIndividual}
          />
          <SizedBox height={1} />
          <CheckBox
            text="I am an individual"
            checked={isIndividual}
            setChecked={setIsIndividual}
          />

          <SizedBox height={2} />

          <InputBox
            label="Phone Number"
            keyboardType="phone-pad"
            value={phoneNumber}
            onChangeText={(text) => setPhoneNumber(text)}
          />

          <SizedBox height={4} />

          <Button text="REGISTER" onPress={registerHandler} loading={loading} />
        </View>
      </View>
      <SizedBox height={10} />
    </ScrollView>
  );
};

export default Register;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: colors.primary,
  },
  topSection: {
    // backgroundColor: "yellow",
    // height: hp(40),
    width: wp(100),
    alignItems: "center",
    justifyContent: "center",
    paddingVertical: hp(2),
  },
  logoImage: {
    // backgroundColor: "red",
    width: hp(20),
    height: hp(20),
  },
  mainText: {
    // backgroundColor: "coral",
    fontFamily: "Poppins-Bold",
    fontSize: RFValue(20),
    color: colors.white,
    // width: wp(40),
    textAlign: "center",
  },
  subText: {
    // backgroundColor: "gold",
    fontFamily: "Poppins-Regular",
    fontSize: RFValue(12),
    color: colors.white,
    // width: wp(30),
    textAlign: "center",
  },
  formSection: {
    backgroundColor: colors.white,
    width: wp(100),
    // height: hp(40),
    paddingVertical: hp(4),
    borderRadius: hp(4),
    alignItems: "center",
    justifyContent: "center",
  },
});
