import React from "react";
import { StyleSheet, Text, View, Platform } from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import Checkbox from "expo-checkbox";

// Constants
import { colors } from "../../assets/constants";

// Components
import SizedBox from "../common/SizedBox";

const CheckBox = (props) => {
  // Prop Destructuring
  const {
    text = "",
    checked = false,
    setChecked = () => {},
    height = 2,
  } = props;
  return (
    <View style={styles.container}>
      <Checkbox
        style={{ height: hp(height) }}
        value={checked}
        onValueChange={() => setChecked((prevState) => !prevState)}
      />
      <SizedBox width={1} />
      <Text style={styles.text}>{text}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "red",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    fontFamily: "Poppins-Regular",
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(12),
  },
});

export default CheckBox;
