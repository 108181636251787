import React, { useState, useEffect, useContext } from "react";
import {
  Platform,
  StyleSheet,
  Text,
  View,
  TextInput,
  TouchableOpacity,
  ScrollView,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Context
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import SizedBox from "../../components/common/SizedBox";
import Header from "../../components/common/Header";
import PageTitle from "../../components/common/PageTitle";
import Package from "./Tabs/Package";
import Pallet from "./Tabs/Pallet";
import Button from "../../components/buttons/Button";

const CalculateShipping = (props) => {
  // Prop Destructuring
  const { navigation } = props;

  // Context Variables
  const { toast } = useContext(Bcontext);

  // State Variables
  const [activeTab, setActiveTab] = useState(1);
  const [loading, setLoading] = useState(false);

  return (
    <ScrollView>
      <Header navigation={navigation} />
      <PageTitle navigation={navigation} text="Calculate Shipping" />

      <SizedBox height={Platform.OS === "web" ? 4 : 0} />

      <View>
        {/* Zip Code Section */}
        <View style={styles.zipContainer}>
          <Text style={styles.zipCodeText}>From zip code</Text>
          <TextInput style={styles.zipInput} />
        </View>

        <SizedBox height={Platform.OS === "web" ? 4 : 2} />

        {/* Tabs Section */}
        <View
          style={{
            ...styles.tabSection,
            flexDirection: activeTab === 1 ? "row" : "row-reverse",
          }}
        >
          <TouchableOpacity
            activeOpacity={0.7}
            style={{
              ...styles.tabBox,
              left: activeTab === 1 ? 0 : -wp(Platform.OS === "web" ? 6 : -8),
            }}
            onPress={() => setActiveTab(1)}
          >
            <Text style={styles.tabText}>Package</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{
              ...styles.tabBox,
              backgroundColor: "yellow",
              left: activeTab === 1 ? -wp(Platform.OS === "web" ? 6 : 8) : 0,
            }}
            activeOpacity={0.7}
            onPress={() => setActiveTab(2)}
          >
            <Text style={styles.tabText}>Pallet</Text>
          </TouchableOpacity>
        </View>

        <SizedBox height={Platform.OS === "web" ? 4 : 2} />

        {/* Table Section */}
        {activeTab === 1 ? <Package /> : <Pallet />}

        <SizedBox height={Platform.OS === "web" ? 10 : 6} />

        <View
          style={{
            width: Platform.OS === "web" ? wp(12) : wp(40),
            alignSelf: "center",
          }}
        >
          <Button
            text="Show Rates"
            onPress={() => {}}
            loading={false}
            disabled={false}
          />
        </View>

        <SizedBox height={16} />
      </View>
    </ScrollView>
  );
};

export default CalculateShipping;

const styles = StyleSheet.create({
  zipContainer: {
    // backgroundColor: "yellow",
    paddingHorizontal: wp(20),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  zipCodeText: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(8) : RFValue(14),
  },
  zipInput: {
    borderWidth: 1,
    borderColor: colors.cardGrey,
    width: Platform.OS === "web" ? wp(8) : wp(20),
    paddingHorizontal: hp(1.5),
    paddingVertical: hp(1),
    marginHorizontal: hp(1),
    marginVertical: hp(1),
  },
  tabSection: {
    // backgroundColor: "yellow",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  tabBox: {
    backgroundColor: colors.primary,
    paddingHorizontal: wp(10),
    paddingVertical: hp(1.5),
    borderRadius: hp(4),
    position: "relative",
  },
  tabText: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(9) : RFValue(16),
  },
});
