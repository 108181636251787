import React, { useContext, useState, useEffect } from "react";
import {
  StyleSheet,
  Text,
  View,
  Platform,
  ActivityIndicator,
} from "react-native";
import { StatusBar } from "expo-status-bar";

// Context
import { Bcontext } from "../context/Bcontext";
import { colors } from "../assets/constants";

// Stack Screens
import AuthStackScreen from "../routes/StackNavigation/AuthStackScreen";
import HomeStackScreen from "../routes/StackNavigation/HomeStackScreen";
// import PriceListStackScreen from "../routes/StackNavigation/PriceListStack";

const Main = () => {
  // Context Variables
  const { authState } = useContext(Bcontext);

  // State Variables
  const [loading, setLoading] = useState(Platform.OS === "web");

  // Shows loading till we fetch the prev login status of user from Global State
  if (loading) {
    setTimeout(() => setLoading(false), 1000);
    return (
      <View style={styles.container}>
        <ActivityIndicator size="large" color={colors.white} />
      </View>
    );
  }

  return (
    <>
      <StatusBar style="auto" />
      {!authState.isLogged ? (
        <AuthStackScreen />
      ) : (
        <>
          <HomeStackScreen />
        </>
      )}
    </>
  );
};

export default Main;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.primary,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
});
