import React, { useState, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  Platform,
  ScrollView,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Context
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

// Components
import Header from "../../components/common/Header";
import ScrapeYard from "../../components/banners/ScrapeYard";
import SizedBox from "../../components/common/SizedBox";
import MenuIcon from "../../components/buttons/MenuIcon";

const menuList = [
  {
    id: 1,
    name: "Price List",
    icon: require("../../assets/images/price-icon.png"),
    screen: "PriceList",
  },
  {
    id: 2,
    name: "Previous Shipments",
    icon: require("../../assets/images/shipment-icon.png"),
    screen: "PreviousShipments",
  },
  {
    id: 3,
    name: "Metal market",
    icon: require("../../assets/images/market-icon.png"),
    screen: "HtmlView",
  },
  {
    id: 4,
    name: "How we work",
    icon: require("../../assets/images/work-icon.png"),
    screen: "HtmlView",
  },
  {
    id: 5,
    name: "Videos",
    icon: require("../../assets/images/video-icon.png"),
    screen: "HtmlView",
  },
  {
    id: 6,
    name: "Calculate Shipping",
    icon: require("../../assets/images/shipment-icon.png"),
    screen: "CalculateShipping",
  },
];

const Home = (props) => {
  // Prop Destructuring
  const { navigation } = props;

  // Context Variables
  const { authState } = useContext(Bcontext);

  // State Variables
  const [loading, setLoading] = useState(false);

  return (
    <ScrollView>
      <Header navigation={navigation} />
      <ScrapeYard navigation={navigation} />

      {/* Main Menu Section */}
      <View style={styles.mainSection}>
        <SizedBox height={Platform.OS === "web" ? 6 : 2} />
        <Image
          source={require("../../assets/images/grey-line.png")}
          style={styles.menuImage}
          resizeMode="contain"
        />
        <Text style={styles.menuTitle}>Main Menu</Text>

        <SizedBox height={2} />

        {/* Menu Icons */}
        <View style={styles.iconsContainer}>
          {menuList.map((item) => (
            <MenuIcon key={item.id} item={item} navigation={navigation} />
          ))}
        </View>
      </View>

      <SizedBox height={20} />
    </ScrollView>
  );
};

export default Home;

const styles = StyleSheet.create({
  mainSection: {
    // backgroundColor: "red",
    alignItems: "center",
    width: wp(80),
    alignSelf: "center",
  },
  menuImage: {
    // backgroundColor: "red",
    width: wp(60),
    height: hp(4),
  },
  menuTitle: {
    fontFamily: "Poppins-Medium",
    fontSize: Platform.OS === "web" ? RFValue(16) : RFValue(22),
  },
  iconsContainer: {
    // backgroundColor: "yellow",
    width: "100%",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-around",
  },
});
