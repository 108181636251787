import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Platform,
  ScrollView,
  TextInput,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";
import * as ImagePicker from "expo-image-picker";

// Context Variables
import { Bcontext } from "../../../context/Bcontext";
import { colors } from "../../../assets/constants";

// Components
import SizedBox from "../../../components/common/SizedBox";
import Header from "../../../components/common/Header";
import PageTitle from "../../../components/common/PageTitle";
import TextArea from "../../../components/inputs/TextArea";
import Button from "../../../components/buttons/Button";

const ImportBulk = (props) => {
  // Prop Destructuring
  const { navigation } = props;

  // Context Variables
  const { toast, addNewItem } = useContext(Bcontext);

  // State Variables
  const [image, setImage] = useState(null);
  const [weight, setWeight] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);

  const descritionLimit = 150;

  // Pick image handler for Android / IOS / Web
  const pickImage = async () => {
    // No permissions request is necessary for launching the image library
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      aspect: [4, 3],
      quality: 1,
    });

    console.log("check image picker result - ", result);

    if (!result.canceled) {
      setImage(result.assets[0].uri);
    }
  };

  const submitHandler = async () => {
    setLoading(true);
    try {
      if (!image || !description) {
        toast.show("Please enter all fields !");
        return setLoading(false);
      }

      // console.log("check image - ", image);
      // return;

      const itemRes = await addNewItem({ name: description, image });
      console.log("Check item add res - ", itemRes);
      navigation.goBack();
    } catch (err) {
      console.log("Some issue while uploading (UploadBulk.js )- ", err);
    }
    setLoading(false);
  };

  return (
    <ScrollView>
      <Header navigation={navigation} />
      <PageTitle navigation={navigation} text="" />

      <View style={{ alignItems: "center" }}>
        <TouchableOpacity
          style={{ alignItems: "center" }}
          activeOpacity={0.7}
          onPress={pickImage}
        >
          <Image
            source={
              image
                ? { uri: image }
                : require("../../../assets/images/background-icon.png")
            }
            style={styles.image}
            resizeMode="contain"
          />
          <Text style={styles.imageText}>Click to upload a photo</Text>

          <SizedBox height={4} />

          <Image
            source={require("../../../assets/images/upload-icon.png")}
            style={styles.icon}
            resizeMode="contain"
          />
        </TouchableOpacity>

        <View style={{ flexDirection: "row", alignItems: "center" }}>
          <Text style={styles.weightText}>
            Please enter estimated weight you have{" "}
          </Text>
          <TextInput
            style={styles.weightInput}
            placeholder={Platform.OS === "web" ? "" : "10.3"}
            keyboardType="number-pad"
            value={weight}
            onChangeText={(text) => setWeight(text)}
          />
          <Text style={styles.weightText}>LBS</Text>
        </View>

        <SizedBox height={4} />

        <View>
          <Text style={styles.descritionText}>Please enter description</Text>
          <TextArea
            value={description}
            onChangeText={(text) => {
              if (description.length < descritionLimit) setDescription(text);
              else
                setDescription((prevState) =>
                  prevState.substring(0, descritionLimit - 1)
                );
            }}
          />
          <Text
            style={{
              ...styles.limitText,
              color:
                description.length < descritionLimit ? colors.primary : "red",
            }}
          >
            {description.length}/{descritionLimit}
          </Text>
        </View>

        <SizedBox height={4} />
        <View style={{ width: wp(40), alignSelf: "center" }}>
          <Button text="Submit" onPress={submitHandler} loading={loading} />
        </View>
        <SizedBox height={10} />
      </View>
    </ScrollView>
  );
};

export default ImportBulk;

const styles = StyleSheet.create({
  image: {
    height: Platform.OS === "web" ? hp(24) : hp(18),
    width: Platform.OS === "web" ? hp(24) : hp(18),
  },
  imageText: {
    fontSize: Platform.OS === "web" ? RFValue(8) : RFValue(12),
    fontFamily: "Poppins-Regular",
  },
  icon: {
    height: Platform.OS === "web" ? hp(12) : hp(8),
    width: Platform.OS === "web" ? hp(12) : hp(8),
  },
  weightText: {
    fontSize: Platform.OS === "web" ? RFValue(7) : RFValue(11),
    fontFamily: "Poppins-Medium",
  },
  weightInput: {
    borderWidth: 1,
    borderColor: colors.cardGrey,
    width: Platform.OS === "web" ? wp(4) : wp(14),
    paddingHorizontal: hp(1.5),
    paddingVertical: hp(1),
    marginHorizontal: hp(1),
    marginVertical: hp(1),
  },
  descritionText: {
    fontSize: Platform.OS === "web" ? RFValue(8) : RFValue(12),
    fontFamily: "Poppins-Regular",
  },
  limitText: {
    fontFamily: "Poppins-SemiBold",
    fontSize: Platform.OS === "web" ? RFValue(5) : RFValue(10),
    textAlign: "right",
  },
});
