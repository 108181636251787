import React, { useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  Image,
  TouchableOpacity,
  Platform,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Constants
import { Bcontext } from "../../context/Bcontext";
import { colors } from "../../assets/constants";

const ScrapeYard = (props) => {
  // Prop Destructuring
  const { navigation = null } = props;

  // Context Variables
  const {} = useContext(Bcontext);

  return (
    <TouchableOpacity
      activeOpacity={0.7}
      onPress={() => {}}
      style={styles.container}
    >
      <View style={styles.imageSection}>
        <Image
          style={styles.image}
          source={require("../../assets/images/scrape-man.png")}
        />
      </View>
      <View style={styles.textSection}>
        <Text style={styles.title}>ELECTRONIC SCRAP</Text>
        <Text style={styles.subTitle}>
          Click here and find out how it works
        </Text>
      </View>
    </TouchableOpacity>
  );
};

export default ScrapeYard;

const styles = StyleSheet.create({
  container: {
    backgroundColor: colors.grey,
    width: wp(100),
    height: Platform.OS === "web" ? hp(20) : hp(15),
    paddingHorizontal: Platform.OS === "web" ? hp(12) : hp(4),
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  imageSection: {
    // backgroundColor: "yellow",
    // flex: 1,
    width: wp(20),
    alignItems: "center",
    justifyContent: "center",
  },
  image: {
    // backgroundColor: "red",
    resizeMode: "contain",
    width: Platform.OS === "web" ? hp(10) : hp(8),
    height: Platform.OS === "web" ? hp(14) : hp(12),
  },
  textSection: {
    // backgroundColor: "green",
    // flex: 4,
    width: wp(70),
  },
  title: {
    fontFamily: Platform.OS === "web" ? "Poppins-SemiBold" : "Poppins-Bold",
    fontSize: RFValue(18),
    letterSpacing: hp(0.3),
  },
  subTitle: {
    // fontFamily: "Poppins-Regular",
    fontSize: RFValue(14),
    letterSpacing: hp(0.5),
    color: "#005CD6",
  },
});
