import React, { useState, useEffect, useContext } from "react";
import {
  StyleSheet,
  Text,
  View,
  ActivityIndicator,
  Platform,
  ScrollView,
  FlatList,
} from "react-native";
import {
  widthPercentageToDP as wp,
  heightPercentageToDP as hp,
} from "react-native-responsive-screen";
import { RFPercentage, RFValue } from "react-native-responsive-fontsize";

// Context
import { Bcontext } from "../../../context/Bcontext";
import { colors } from "../../../assets/constants";

// Components
import SizedBox from "../../../components/common/SizedBox";
import PageTitle from "../../../components/common/PageTitle";
import Header from "../../../components/common/Header";
import ProductCard from "../../../components/cards/ProductCard";

const Listing = (props) => {
  // Prop Destructuring
  const { navigation, route } = props;

  // Context Variables
  const { toast, getProducts } = useContext(Bcontext);

  // State Variables
  const [productList, setProductList] = useState([]);
  const [loading, setLoading] = useState(true);

  // Mounting
  useEffect(() => {
    fetchProducts();
  }, []);

  const fetchProducts = async () => {
    setLoading(true);
    try {
      console.log("check screen props - ", props);
      const prodRes = await getProducts();
      console.log("check listing props - ", prodRes);
      if (prodRes.statusCode == 200) {
        setProductList(
          route?.params?.screenName === "ALL ITEMS"
            ? prodRes.data
            : prodRes.data.filter(
                (item) =>
                  item.Category_for_all_list__c == route?.params?.screenName
              )
        );
      }
    } catch (err) {
      console.log("Some issue while fetching products (Listing.js) - ", err);
    }
    setLoading(false);
  };

  return (
    <ScrollView>
      <Header navigation={navigation} />
      <PageTitle
        navigation={navigation}
        text={`Price List - ${route?.params?.screenName}`}
      />

      {/* Products List */}
      {loading ? (
        <View style={{ alignItems: "center", justifyContent: "center" }}>
          <ActivityIndicator size="small" color={colors.primary} />
        </View>
      ) : null}
      <View
        style={{ paddingHorizontal: Platform.OS === "web" ? wp(10) : hp(4) }}
      >
        <FlatList
          data={productList}
          renderItem={({ item }) => (
            <>
              <ProductCard
                name={item.Name}
                image={item.BW_Photo_url__c}
                category={item.Category__c}
                price={item.Price_to_pay_customer__c}
                favorite={item.is_favourite}
                id={item.Id}
              />
              <SizedBox height={2} />
            </>
          )}
          keyExtractor={(item) => item.Id}
        />
      </View>

      <SizedBox height={6} />
    </ScrollView>
  );
};

export default Listing;

const styles = StyleSheet.create({});
